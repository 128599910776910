import React from 'react'
import styles from './mf_thank_you.module.css'

export default function MfThankYou({}) {
  return (
    <div className={styles.thank_you_container}>
      <h3 className={styles.header_three} style={{fontWeight: '700'}}>Thank you for your interest!</h3>
      <div className={styles.thank_you_text}>
        We're sorry, but this program is specifically for patients and caregivers of Myelofibrosis (MF).
        Please reach out to your healthcare provider for further assistance.
        Or contact us if you feel like you got this in error at <a className={styles.thank_you_link} href="mailto:info@patientpartner.com">info@patientpartner.com</a>.
      </div>
    </div>
  )
}
