import React from 'react'
import { Link } from 'react-router-dom'
import FeaturedInMedia from '../featured_in_media'
import ImgLeftTextRightSimple from '../hero_sections/img_left_text_right_simple'
import ImgRightTextLeftSimple from '../hero_sections/img_right_text_left_simple'
import ImgRightTextLeft from '../hero_sections/img_right_text_left'
import TwoTestimonials from '../two_testimonials'
import styles from './index.module.css'
import SingleVideoCenteredWDecor from '../video_sections/single_video_centered_w_decor'
import WaveSeparator from '../ui_kit/utilities/wave_separator'
import JoinFooter from '../join_footer'

export default function Home({
  updateAnswersAction
}) {

  return (
    <div>
      <ImgRightTextLeft
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/hero_partners.jpg'
        bg='#DDF7F9'
        headline='Your partner through healthcare'
        para='Join a community of similar patients and verified doctors to support you every step of the way.'
        marginTopPhotoMobile='30px'
      />
      <FeaturedInMedia
        images={[
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/forbes.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/abc.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/yahoo_a.jpg',
          'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/nbc_a.jpg'
        ]}
      />
      <ImgLeftTextRightSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/featured_in_media/patient_partner_connection.jpg'
        bg='#fff'
        headline='Join a community of like minded patients'
        para='Connect with others to learn from personal insights, success stories, and advice on the treatment you’re considering. Find the support from a community that’s focused on useful healthcare information and advice.'
        actionContent={<></>}
        headlineType='h2'
      />
      <TwoTestimonials
        testimonials={[
          {
            text: "Joining the PatientPartner community was easily the highlight of my week. I feel like I've made new friends, and I'm more than confident that I'm ready for this treatment.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/Kate_avatar.jpg',
            name: 'Kate',
            city: 'Dallas, TX'
          },
          {
            text: "A community like PatientPartner is so important because sometimes you just don’t know where to go. You need that community to lean on and trust.",
            image: 'https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/charles_avatar.jpg',
            name: 'Charles',
            city: 'San Diego, CA'
          },
        ]}
      />
      <ImgLeftTextRightSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/find-a-mentor-for-you.jpg'
        bg='#fff'
        headline='Meet our network of mentors'
        para='Speak privately with a PatientPartner Mentor, a verified recovered patient who has already been through the same treatment. They volunteer their time to answer any sensitive questions and guide you throughout your treatment journey.'
        headlineType='h2'
        actionContent={<Link to="/partners"><button data-gtm-click-label="Home Find my mentor" style={{ padding: '12px 20px' }}>Find my mentor</button></Link>}
      />
      <ImgRightTextLeftSimple
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/home_page/find-a-doctor.jpg'
        bg='#fff'
        headline='Find and instantly connect with the best doctor'
        para='Speak privately with a PatientPartner Mentor, a verified recovered patient who has already been through the same procedure. They volunteer their time to answer any sensitive questions and guide you before or after your surgery. '
        headlineType='h2'
        headlineContent={(<>Find and instantly connect with the <span className="highlight-blue">best doctor</span></>)}
        middleContent={(
          <ul className={styles.unlist}>
            <li className={styles.list_item}>Find the top-rated specialist in your area who’s <span className="highlight-blue">covered by your insurance.</span></li>
            <li className={styles.list_item}>Schedule a consultation and <span className="highlight-blue">chat with a doctor</span> on our platform in minutes.</li>
            <li className={styles.list_item}><span className="highlight-blue">Speak with their past patients</span> to hear honest feedback about their experience.</li>
          </ul>
        )}
        actionContent={<Link to="/member/join/surgeon-match"><button className="liner" data-gtm-click-label="Home Find my mentor" style={{ padding: '12px 20px' }}>Find a doctor</button></Link>}
      />
      <ImgRightTextLeftSimple
        title="Industry Partners"
        img='https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/industrypartners-min.png'
        bg='#ddf7f9'
        headline='Patients sharing how your products changed their lives.'
        para=''
        headlineType='h2'
        middleContent={(
          <ul className={styles.unlist}>
            <li className={styles.list_item}>Learn how your current patients can grow the awareness of your products with prospective patients.</li>
            <li className={styles.list_item}>Have a deeper understanding why patients are interested in your products.</li>
            <li className={styles.list_item}>Provide a dynamic way for new patients to find your physician customers.</li>
          </ul>
        )}
        actionContent={<Link to="/industry-partners"><button data-gtm-click-label="Home Industry Partners" style={{ padding: '12px 20px' }}>Learn More</button></Link>}
      />
      <SingleVideoCenteredWDecor
        videoBg="https://patientpartner-images2.s3.us-west-1.amazonaws.com/website/videomentorbg.jpg"
        videoLink="https://www.youtube.com/embed/t_6d-2PCcLI?si=AEj3HYWYUeqO9xDr"
        bgColor='#EFEFEF'
      />
      <WaveSeparator/>
      <JoinFooter
        updateAnswersAction={updateAnswersAction}
      />
    </div>
  )
}
